<template>
  <VueHighcharts
    v-if="showChart && !refreshChart"
    type="chart"
    :redrawOnUpdate="true"
    :oneToOneUpdate="true"
    :animateOnUpdate="true"
    :options="chartOptions"
  />
</template>

<script setup>
import { computed, defineEmits, defineProps, nextTick, ref, watch } from "vue";
import VueHighcharts from "vue3-highcharts";
import { useStore } from "vuex";

const emit = defineEmits(["update:loading"]);

const props = defineProps({
  dateFrom: {
    type: String,
  },
  dateTo: {
    type: String,
  },
  departuresKey: {
    type: Array,
  },
  isAccumulative: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: true,
  },
});

const store = useStore();

const showChart = ref(false);
const chartData = ref([]);
const refreshChart = ref(false);

const getChartColors = computed(() => store.getters.getChartColors);
const getAccumulatedReport = computed(() => store.getters.getAccumulatedReport);
const chartOptions = computed(() => ({
  chart: { type: "line" },
  title: { text: "HH" },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      day: "%e. %b %Y", // Añadir el año al formato del día
      month: "%e. %b %Y", // Añadir el año al formato del mes
      year: "%b %Y", // Añadir el año al formato del año
    },
  },

  yAxis: { title: { text: "HH" } },
  credits: { enabled: false },
  series: chartData.value,
  tooltip: {
    headerFormat: "<b>{series.name}</b><br>",
    pointFormat: "{point.x:%e. %b %Y}: {point.y:.2f} HH",
  },
  plotOptions: {
    column: {
      pointPadding: 0,
      borderWidth: 0,
    },
    line: {
      dataLabels: {
        enabled: false,
      },
      enableMouseTracking: true,
    },
    series: {
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
    },
  },
  colors: getChartColors.value,
}));

const getShowMenu = computed(() => store.getters.getShowMenu);

watch(
  () => getShowMenu.value,
  () => {
    refreshChart.value = true;
    nextTick(() => {
      refreshChart.value = false;
    });
  }
);

watch(
  () => getAccumulatedReport.value,
  (currentValue) => {
    let data = [];

    if (currentValue && currentValue.length) {
      for (let value of currentValue) {
        data.push({
          name: value.name,
          data: value.resume.map((r) => [Date.parse(r.date), r.hoursWorked]),
        });
      }
    }

    showChart.value = false;
    nextTick(() => {
      showChart.value = true;
      chartData.value = data;
    });
  },
  { deep: true }
);

const loadData = async () => {
  try {
    emit("update:loading", true);

    store.dispatch("resetAccumutaledReport");

    const filter = {
      dateFrom: props.dateFrom,
      departuresKey: props.departuresKey || [],
    };
    if (props.dateTo) {
      filter.dateTo = props.dateTo;
    }

    await store.dispatch("loadAccumulatedReport", filter);
  } catch (error) {
    console.error(error);
  } finally {
    emit("update:loading", false);
  }
};

watch(
  () => props.dateFrom,
  () => {
    loadData();
  }
);

watch(
  () => props.departuresKey,
  () => {
    if (props.isAccumulative) return;
    loadData();
  },
  { deep: true }
);

const init = async () => {
  await loadData();
};

init();
</script>
