<template>
  <Modal :open="open" width="w-1/3" @update:open="onClose">
    <template #body>
      <span class="font-medium">
        ¿Desea eliminar el tareo en la partida "{{
          workHour.items[0].departure.name
        }}" del día {{ formatDate(workHour.date) }} de
        {{ workHour.user.lastName }} {{ workHour.user.lastName2 }}
        {{ workHour.user.name }}?
      </span>
      <br />
    </template>
    <template #buttonDelete>
      <button
        type="button"
        class="
          w-full
          inline-flex
          justify-center
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          bg-red-600
          text-base
          font-medium
          text-white
          hover:bg-red-700
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
          sm:ml-3 sm:w-auto sm:text-sm
        "
        :disabled="loading"
        @click="onDelete"
      >
        <LoadingButtonIcon v-if="loading" />
        Eliminar
      </button>
    </template>
  </Modal>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";

const emit = defineEmits(["update:open"]);

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  workHour: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const loading = ref(false);

const formatDate = (dateString) => {
  return dayjs(dateString).format("DD/MM/YYYY");
};

const onClose = () => {
  emit("update:open", false);
};

const onDelete = async () => {
  try {
    loading.value = true;

    await store.dispatch("deleteWorkHourItem", {
      workHourItemKey: props.workHour.items[0].key,
    });

    onClose();
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};
</script>
