<template>
  <div class="min-h-full overflow-x-scroll">
    <nav
      class="tabs flex flex-row pt-5 border-b px-6 fixed bg-white w-full z-10"
    >
      <button
        class="
          tab
          text-gray-600
          py-2
          px-6
          block
          hover:text-blue-500
          focus:outline-none
        "
        :class="
          mode === 'daily'
            ? 'font-medium border-b-4 border-blue-500 text-blue-500'
            : ''
        "
        @click="mode = 'daily'"
      >
        Diario
      </button>
      <button
        class="
          tab
          active
          text-gray-600
          py-2
          px-6
          block
          hover:text-blue-500
          focus:outline-none
        "
        :class="
          mode === 'weekly'
            ? 'font-medium border-b-4 border-blue-500 text-blue-500'
            : ''
        "
        @click="mode = 'weekly'"
      >
        Semanal
      </button>
      <button
        class="
          tab
          active
          text-gray-600
          py-2
          px-6
          block
          hover:text-blue-500
          focus:outline-none
        "
        :class="
          mode === 'monthly'
            ? 'font-medium border-b-4 border-blue-500 text-blue-500'
            : ''
        "
        @click="mode = 'monthly'"
      >
        Mensual
      </button>
      <button
        class="
          tab
          active
          text-gray-600
          py-2
          px-6
          block
          hover:text-blue-500
          focus:outline-none
        "
        :class="
          mode === 'accumulative'
            ? 'font-medium border-b-4 border-blue-500 text-blue-500'
            : ''
        "
        @click="mode = 'accumulative'"
      >
        Acumulativo
      </button>
    </nav>

    <div v-if="getWeekInitialDate && getDepartures">
      <div v-if="mode === 'daily'">
        <Daily />
      </div>

      <div v-if="mode === 'weekly'">
        <Weekly />
      </div>

      <div v-if="mode === 'monthly'">
        <Monthly />
      </div>

      <div v-if="mode === 'accumulative'">
        <Accumulative />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Daily from "./daily/Daily";
import Weekly from "./weekly/Weekly";
import Monthly from "./monthly/Monthly";
import Accumulative from "./accumulative/Accumulative";

const store = useStore();

const mode = ref("daily");

const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);
const getDepartures = computed(() => store.getters.getDepartures);

const init = async () => {
  if (!getWeekInitialDate.value)
    await store.dispatch("setCurrentWeekInitialDate");

  if (!getDepartures.value) await store.dispatch("getAllDepartures");
};

init();
</script>
