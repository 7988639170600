<template>
  <div class="pt-20 p-4 xl:w-full">
    <div class="lg:p-5 p-5">
      <div class="flex w-full justify-between">
        <input
          v-model="dateFrom"
          type="date"
          min="1970-01-05"
          step="7"
          class="
            relative
            p-2
            bg-white
            rounded
            border
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none
            px-4
          "
        />

        <button
          v-show="!loadingTaskerWeeklyConfig"
          class="
            flex
            text-white
            border
            text-sm
            font-medium
            bg-primary
            border-primary
            py-2
            mx-2
            px-3
            my-auto
          "
          @click="onHandleTaskerWeeklyConfig"
        >
          <LoadingButtonIcon v-if="loadingHandleTaskerWeeklyConfig" />
          {{
            getTaskerDailyConfig && getTaskerDailyConfig.isClosed
              ? "Abrir"
              : "Cerrar"
          }}
          semana
        </button>

        <div class="flex">
          <button
            class="
              flex
              text-white
              border
              text-sm
              font-medium
              bg-primary
              border-primary
              py-2
              mx-2
              px-3
              my-auto
            "
            @click="onOpenDrawerImportTasker(true)"
          >
            Importar
          </button>

          <button
            v-if="
              [
                '5cd56a4e-7217-465c-a36b-97dd9fe037b7',
                '655f8472-88d3-4365-a743-8afe4ca9c84b',
                '766cef75-bfb3-4a0b-8d68-543a11bbf401',
                '65281044-f259-4fff-ba5a-04b5c5f1ea07',
              ].includes(getWork.key) &&
              getUserAuthenticated.speciality &&
              getUserAuthenticated.speciality.name === 'Gerente'
            "
            class="
              flex
              text-white
              border
              text-sm
              font-medium
              bg-primary
              border-primary
              py-2
              ml-4
              px-3
              my-auto
            "
            :disabled="loadingSendToCA"
            @click="onSendToCA"
          >
            <LoadingButtonIcon v-if="loadingSendToCA" /> Enviar CA
          </button>

          <WeeklyExportOptions :date-from="dateFrom" :date-to="dateTo" />
        </div>
      </div>

      <div class="pt-4">
        Resultados del
        <span class="text-primary font-medium"> {{ dateFrom }}</span> al
        <span class="text-primary font-medium">{{ dateTo }}</span>
      </div>
    </div>

    <div v-show="loadingData" class="flex justify-center">
      <img :src="require('@/assets/loader.gif')" width="250" />
    </div>

    <div v-show="!loadingData">
      <div class="mt-16">
        <ChartPieDepartureWorkHours
          :date-from="dateFrom"
          :date-to="dateTo"
          :loading="loadingChartPieDepartureWorkHours"
        />
      </div>

      <div class="mt-16">
        <ChartWorkersCategory
          :date-from="dateFrom"
          :date-to="dateTo"
          :loading="loadingChartWorkersCategory"
        />
      </div>

      <div class="mt-16">
        <ChartBarPieCategoryWorkers
          :date-from="dateFrom"
          :date-to="dateTo"
          :loading="loadingChartBarPieCategoryWorkers"
        />
      </div>
    </div>

    <EndDrawer
      :open="drawerImportTasker"
      @update:open="drawerImportTasker = $event"
    >
      <ImportTaskerForm
        :date="dateFrom"
        @onComplete="onOpenDrawerImportTasker(false)"
      />
    </EndDrawer>

    <SendToCAModal
      v-if="modalSendToCA"
      :open="modalSendToCA"
      @update:open="modalSendToCA = $event"
      :date-from="dateFrom"
      :date-to="dateTo"
    />
  </div>
</template>

<script setup>
import dayjs from "dayjs";
import { computed, ref, watch, nextTick } from "vue";
import { useStore } from "vuex";

import ChartWorkersCategory from "../components/ChartWorkersCategory.vue";
import ChartPieDepartureWorkHours from "../components/ChartPieDepartureWorkHours.vue";
import ChartBarPieCategoryWorkers from "../components/ChartBarPieCategoryWorkers.vue";
import WeeklyExportOptions from "./components/WeeklyExportOptions.vue";
import ImportTaskerForm from "./components/ImportTaskerForm.vue";
import SendToCAModal from "./components/SendToCAModal.vue";

const store = useStore();

const dateFrom = ref("");
const dateTo = ref("");
const refreshChart = ref(false);
const drawerImportTasker = ref(false);
const modalSendToCA = ref(false);
// const loadingConfirmTasker = ref(false);
const loadingSendToCA = ref(false);
const loadingChartWorkersCategory = ref(false);
const loadingChartBarPieCategoryWorkers = ref(false);
const loadingChartPieDepartureWorkHours = ref(false);
const loadingTaskerWeeklyConfig = ref(false);
const loadingHandleTaskerWeeklyConfig = ref(false);

// const getTaskerConfirmation = computed(
//   () => store.getters.getTaskerConfirmation
// );
const getUserAuthenticated = computed(() => store.getters.getUserAuthenticated);
const getWork = computed(() => store.getters.getWork);
const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);
const getTaskerDailyConfig = computed(() => store.getters.getTaskerDailyConfig);
const getShowMenu = computed(() => store.getters.getShowMenu);
const loadingData = computed(
  () =>
    loadingChartWorkersCategory.value &&
    loadingChartBarPieCategoryWorkers.value &&
    loadingChartPieDepartureWorkHours.value
);

watch(
  () => getShowMenu.value,
  () => {
    refreshChart.value = true;

    nextTick(() => {
      refreshChart.value = false;
    });
  }
);

const loadTaskerWeeklyConfig = async () => {
  try {
    loadingTaskerWeeklyConfig.value = true;

    await store.dispatch("findTaskerDailyConfig", {
      date: dateFrom.value,
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingTaskerWeeklyConfig.value = false;
  }
};

watch(
  () => dateFrom.value,
  () => {
    dateTo.value = dayjs(dateFrom.value).add(6, "day").format("YYYY-MM-DD");

    loadTaskerWeeklyConfig();
  }
);

const init = () => {
  dateFrom.value = getWeekInitialDate.value;
};

init();

const onHandleTaskerWeeklyConfig = async () => {
  try {
    loadingHandleTaskerWeeklyConfig.value = true;

    await store.dispatch("handleTaskerDailyConfigByWeek", {
      config: {
        initialDate: dateFrom.value,
        isClosed: getTaskerDailyConfig.value
          ? !getTaskerDailyConfig.value.isClosed
          : true,
      },
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingHandleTaskerWeeklyConfig.value = false;
  }
};

const onSendToCA = async () => {
  try {
    loadingSendToCA.value = true;

    const reportUrl = await store.dispatch(
      "exportAllWorkHoursAndCategoryCost",
      {
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
      }
    );

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", `resumen-semanal.xlsx`);
    document.body.appendChild(link);
    link.click();

    modalSendToCA.value = true;
  } catch (error) {
    console.error(error);
  } finally {
    loadingSendToCA.value = false;
  }
};

const onOpenDrawerImportTasker = (open = true) => {
  drawerImportTasker.value = open;
};
</script>
