<template>
  <Menu as="div" class="relative inline-block text-left ml-4 my-auto">
    <div>
      <MenuButton
        class="
          text-white
          border
          text-sm
          font-medium
          bg-greenDark
          border-greenDark
          py-2
          px-5 px-6
          mx-2
          flex
        "
      >
        <LoadingButtonIcon v-if="loadingExport" /> Exportar
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="
          absolute
          right-0
          z-10
          mt-2
          w-56
          origin-top-right
          divide-y divide-gray-100
          rounded-md
          bg-white
          shadow-lg
          ring-1 ring-black ring-opacity-5
          focus:outline-none
        "
      >
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <button
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'px-4 py-2 text-sm w-full text-left',
              ]"
              :disabled="loadingExportHistory"
              @click="onExportHistory"
            >
              Base de datos
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { defineProps, ref } from "vue";
import { useStore } from "vuex";
const props = defineProps({
  dateFrom: {
    type: String,
  },
  dateTo: {
    type: String,
  },
});
const store = useStore();
const loadingExport = ref(false);
const loadingExportHistory = ref(false);

const onExportHistory = async () => {
  try {
    loadingExportHistory.value = true;

    const reportUrl = await store.dispatch("exportTaskerHistoryReport", {
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    });

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", `tareo-base-de-datos.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error(error);
  } finally {
    loadingExportHistory.value = false;
  }
};
</script>
