<template>
  <div :class="cardClasses">
    <div class="flex items-center">
      <div
        v-if="isSingleDeparture"
        class="text-sm text-secondary items-center font-bold md:mb-0 mr-2"
      >
        HH Totales {{ getTotalHoursName }}:
      </div>
      <div v-else>
        <div class="flex items-center justify-between md:justify-start">
          <div
            class="
              w-8
              h-8
              bg-white
              p-2
              flex
              items-center
              justify-center
              cursor-pointer
            "
            @click="showDetail = !showDetail"
          >
            <svg
              v-if="showDetail"
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.59 8L6 3.05121L1.41 8L0 6.469L6 0L12 6.469L10.59 8Z"
                fill="#3D3D67"
              />
            </svg>
            <svg
              v-else
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.41 0L6 4.94879L10.59 0L12 1.531L6 8L0 1.531L1.41 0Z"
                fill="#3D3D67"
              />
            </svg>
          </div>
          <div class="text-sm text-secondary font-bold mx-2">HH Totales:</div>
        </div>
      </div>
      <span> {{ formattedTotalHours }} HH</span>
    </div>
    <div v-if="!isSingleDeparture && showDetail" class="pl-2 md:pl-10 pt-4">
      <div class="w-full pb-2 md:pr-4 md:divide-y divide-dashed">
        <div
          v-for="(item, index) in sortedResponseDepartureTotalHours"
          :key="index"
          class="grid grid-cols-2 md:grid-cols-5 md:gap-2 items-center"
        >
          <!--
          <div
            class="
              text-wrap
              col-span-2
              text-secondary
              font-medium
              flex-1
              text-sm
              mr-2
              max-w-xs
              md:max-w-sm
            "
          >
            {{
              item.isSubdeparture && item.isSubdeparture === true
                ? "───  " + item.name
                : item.name
            }}:
          </div>
          -->
          <div
            class="
              text-wrap
              col-span-2
              text-secondary
              font-medium
              flex-1
              text-sm
              mr-2
              max-w-xs
              md:max-w-sm
            "
          >
            {{ item.name }}:
          </div>
          <div class="flex-1 text-wrap">
            {{ formatNumber(item.hoursWorked) }} HH
          </div>
          <div
            class="
              col-span-2
              md:mb-5 md:mt-2 md:ml-4
              bg-gray-200
              rounded-full
              h-2.5
              dark:bg-gray-700
            "
          >
            <div
              class="h-2.5 rounded-full"
              :class="{
                'bg-green-500': calculateHoursWorkedPercentage(item) <= 100,
                'bg-red-500': calculateHoursWorkedPercentage(item) > 100,
              }"
              :style="{
                width: `${Math.min(
                  calculateHoursWorkedPercentage(item),
                  100
                )}%`,
              }"
            ></div>
            <div
              class="hidden md:block text-right font-bold text-xs mb-0 mt-0"
              :class="{
                'text-green-500': calculateHoursWorkedPercentage(item) <= 100,
                'text-red-500': calculateHoursWorkedPercentage(item) > 100,
              }"
            >
              {{ formatNumber(calculateHoursWorkedPercentage(item)) }}%
            </div>
          </div>
          <div
            class="block md:hidden font-bold text-sm mb-4 mt-1"
            :class="{
              'text-green-500': calculateHoursWorkedPercentage(item) <= 100,
              'text-red-500': calculateHoursWorkedPercentage(item) > 100,
            }"
          >
            {{ formatNumber(calculateHoursWorkedPercentage(item)) }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from "vue";

const props = defineProps({
  departuresKey: Array,
  responseDepartureTotalHours: Array,
});

const showDetail = ref(false);
const isSingleDeparture = ref(false);

const getTotalHoursName = computed(() =>
  isSingleDeparture.value
    ? props.responseDepartureTotalHours[0].name
    : "Totales"
);

const getTotalHours = computed(() =>
  isSingleDeparture.value
    ? props.responseDepartureTotalHours[0].hoursWorked
    : props.responseDepartureTotalHours.reduce(
        (total, item) => total + item.hoursWorked,
        0
      )
);

const formattedTotalHours = computed(() => formatNumber(getTotalHours.value));

const sortedResponseDepartureTotalHours = computed(() =>
  [...props.responseDepartureTotalHours].sort(
    (a, b) => b.hoursWorked - a.hoursWorked
  )
);

const cardClasses = computed(
  () => `
  ml-5
  w-max
  grid grid-cols-1
  text-left text-secondary
  font-light
  px-6
  py-4
  mb-4
  bg-lightgray
`
);

const init = () => {
  isSingleDeparture.value = props.departuresKey.length === 1;
};

init();

const calculateHoursWorkedPercentage = (item) => {
  if (item.hoursWorked && item.budgetHours && item.budgetHours !== 0) {
    return (item.hoursWorked * 100) / item.budgetHours;
  }
  return 0;
};

const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};
</script>
