<template>
  <div>
    <div class="mt-16">
      <VueHighcharts
        v-if="showChart && !refreshChart"
        type="chart"
        :redrawOnUpdate="true"
        :oneToOneUpdate="true"
        :animateOnUpdate="true"
        :options="barChartCategoryOptions"
      />
    </div>
    <div class="mt-16">
      <VueHighcharts
        v-if="showChart && !refreshChart"
        type="chart"
        :redrawOnUpdate="true"
        :oneToOneUpdate="true"
        :animateOnUpdate="true"
        :options="pieChartCategoryOptions"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, nextTick, ref, watch } from "vue";
import VueHighcharts from "vue3-highcharts";
import { useStore } from "vuex";

const emit = defineEmits(["update:loading"]);

const props = defineProps({
  dateFrom: {
    type: String,
  },
  dateTo: {
    type: String,
  },
  departuresKey: {
    type: Array,
  },
  loading: {
    type: Boolean,
    default: true,
  },
});

const store = useStore();

const showChart = ref(false);
const chartData = ref([]);
const refreshChart = ref(false);

const getShowMenu = computed(() => store.getters.getShowMenu);
const getChartColors = computed(() => store.getters.getChartColors);
const getCategoryReport = computed(() => store.getters.getCategoryReport);
const pieChartCategoryOptions = computed(() => ({
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  colors: getChartColors.value,
  title: { text: "Cantidad de trabajadores por categoría" },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        connectorColor: "#777",
        format: "<b>{point.name}</b>: {point.percentage:.1f} %",
      },
    },
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        format: "<b>{point.name}</b>: {point.percentage:.1f} %",
      },
      showInLegend: true,
    },
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      name: "Cantidad trabajadores",
      //innerSize: "20%",
      data: chartData.value,
    },
  ],
}));
const barChartCategoryOptions = computed(() => ({
  chart: {
    type: "column",
    options3d: {
      enabled: true,
      alpha: 45,
    },
  },
  colors: getChartColors.value,
  title: { text: "Cantidad de trabajadores por categoría" },
  yAxis: {
    title: {
      text: "Cantidad de trabajadores",
    },
  },
  xAxis: {
    type: "category",
  },
  plotOptions: {
    pie: {
      innerSize: 100,
      depth: 45,
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      name: "Trabajadores",
      data: chartData.value,
      dataSorting: {
        enabled: true,
      },
      dataLabels: {
        enabled: true,
        // rotation: -90,
        color: "#FFFFFF",
        // align: "right",
        format: "{point.y}",
        y: 10, // 10 pixels down from the top
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
  ],
}));

watch(
  () => getShowMenu.value,
  () => {
    refreshChart.value = true;
    nextTick(() => {
      refreshChart.value = false;
    });
  }
);

watch(
  () => getCategoryReport.value,
  (currentValue) => {
    showChart.value = false;

    const data = currentValue?.map((v) => [v.name, v.userWorked]) || [];

    nextTick(() => {
      showChart.value = true;
      chartData.value = data;
    });
  }
);

const loadData = async () => {
  try {
    emit("update:loading", true);

    await store.dispatch("loadCategoryReport", {
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    });
  } catch (error) {
    console.error(error);
  } finally {
    emit("update:loading", false);
  }
};

watch(
  () => props.dateFrom,
  () => loadData()
);

watch(
  () => props.dateTo,
  () => loadData()
);

watch(
  () => props.departuresKey,
  () => loadData(),
  { deep: true }
);

const init = async () => {
  await loadData();
};

init();
</script>
