<template>
  <div class="p-4 xl:w-full lg:w-screen pt-20">
    <div class="lg:p-5 flex align-start p-5">
      <input
        v-model="month"
        type="month"
        min="2021-01-01"
        class="
          relative
          p-2
          bg-white
          rounded
          border
          outline-none
          text-gray-700
          placeholder-gray-400
          focus:outline-none
          px-4
        "
      />

      <div class="w-1/2 lg:w-full pl-5">
        <Multiselect
          v-model="departuresKey"
          mode="tags"
          noOptionsText="No hay partidas"
          noResultsText="No hay resultados"
          :options="getDeparturesList"
        />
      </div>

      <MonthlyExportOptions :date-from="dateFrom" :date-to="dateTo" />
    </div>

    <div
      v-show="
        loadingLineDepartureWorkHours ||
        loadingChartDepartureCategoryCost ||
        loadingChartDepartureWorkHoursComparative
      "
      class="flex justify-center"
    >
      <img :src="require('@/assets/loader.gif')" width="250" />
    </div>

    <div
      v-show="
        !loadingLineDepartureWorkHours &&
        (!loadingChartDepartureCategoryCost ||
          !loadingChartDepartureWorkHoursComparative)
      "
    >
      <div>
        <ChartLineDepartureWorkHours
          :date-from="dateFrom"
          :date-to="dateTo"
          :departures-key="departuresKey"
          :loading="loadingLineDepartureWorkHours"
          @update:loading="loadingLineDepartureWorkHours = $event"
        />
      </div>

      <!--
      <div class="mt-16">
        <ChartDepartureWorkHours :date-from="dateFrom" :date-to="dateTo" />
      </div>
      -->

      <div
        v-if="
          getWork &&
          [
            'c64fb811-f3f6-4160-8a34-9adb1ffdb3d6',
            'df213990-73b9-4a2e-82f5-6d37b1d891f5',
            '766cef75-bfb3-4a0b-8d68-543a11bbf401', // Sol de Pimentel
            '65281044-f259-4fff-ba5a-04b5c5f1ea07', // Sol de Chan Chan
            '8beafab5-93f7-4acd-bce5-7bba459dca0d', // Sol de Lambayeque
            '8ca68aae-1d7a-4fda-a45e-613aecd370e7', // Vista Alegre
          ].includes(getWork.key)
        "
        class="mt-16"
      >
        <ChartDepartureWorkHoursComparative
          :date-from="dateFrom"
          :date-to="dateTo"
          :departures-key="departuresKey"
          :loading="loadingChartDepartureWorkHoursComparative"
          @update:loading="loadingChartDepartureWorkHoursComparative = $event"
        />
      </div>
      <div v-else class="mt-16">
        <ChartDepartureCategoryCost
          :date-from="dateFrom"
          :date-to="dateTo"
          :departures-key="departuresKey"
          :loading="loadingChartDepartureCategoryCost"
          @update:loading="loadingChartDepartureCategoryCost = $event"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Multiselect from "@vueform/multiselect";
import dayjs from "dayjs";
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";
import ChartDepartureCategoryCost from "../components/ChartDepartureCategoryCost.vue";
import ChartLineDepartureWorkHours from "../components/ChartLineDepartureWorkHours.vue";
import MonthlyExportOptions from "./components/MonthlyExportOptions.vue";
import ChartDepartureWorkHoursComparative from "../components/ChartDepartureWorkHoursComparative.vue";

const store = useStore();

const month = ref("");
const dateFrom = ref("2021-01-01");
const dateTo = ref("");
const departuresKey = ref([]);
const loadingLineDepartureWorkHours = ref(true);
const loadingChartDepartureCategoryCost = ref(true);
const loadingChartDepartureWorkHoursComparative = ref(true);

const getWork = computed(() => store.getters.getWork);
const getDepartures = computed(() => store.getters.getDepartures);
const getDeparturesList = computed(() =>
  getDepartures.value?.map((d) => ({ label: d.name, value: d.key }))
);

const getFirstAndLastDayOfMonth = (date) => {
  let d = dayjs(date);

  dateFrom.value = d.startOf("month").format("YYYY-MM-DD");
  dateTo.value = d.endOf("month").format("YYYY-MM-DD");
};

watch(
  () => month.value,
  (currentValue) => {
    const year = currentValue.split("-")[0];
    const month = currentValue.split("-")[1];
    const date = new Date(`${year}-${month}-1`);
    getFirstAndLastDayOfMonth(date);
  }
);

const init = async () => {
  if (
    getWork.value &&
    [
      "c64fb811-f3f6-4160-8a34-9adb1ffdb3d6",
      "df213990-73b9-4a2e-82f5-6d37b1d891f5",
      "766cef75-bfb3-4a0b-8d68-543a11bbf401", // Sol de Pimentel
      "65281044-f259-4fff-ba5a-04b5c5f1ea07", // Sol de Chan Chan
      "8beafab5-93f7-4acd-bce5-7bba459dca0d", // Sol de Lambayeque
      "8ca68aae-1d7a-4fda-a45e-613aecd370e7", // Vista Alegre
    ].includes(getWork.value.key)
  )
    loadingChartDepartureCategoryCost.value = false;
  else loadingChartDepartureWorkHoursComparative.value = false;

  if (!getDepartures.value) await store.dispatch("getAllDepartures");

  const d = new Date();
  month.value = `${d.getFullYear()}-${d.getMonth() + 1}`;
  getFirstAndLastDayOfMonth(d);
};

init();
</script>
