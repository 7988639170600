<template>
  <div class="p-4 xl:w-full lg:w-screen pt-20">
    <div v-if="mode === 'table'" class="grid grid-cols-2 my-2 mr-3 ml-2">
      <div class="flex justify-start flex-col relative">
        <p class="text-left pr-2 text-sm font-medium text-secondary">
          Buscar usuario:
        </p>
        <div class="flex flex-col justify-end">
          <input
            v-model.trim="search"
            class="
              relative
              p-1
              px-3
              lg:w-60
              bg-white
              rounded-sm
              border border-gray-400
              outline-none
              text-gray-700
              placeholder-gray-400
              pr-10
              h-8
            "
          />
        </div>
      </div>
      <div class="flex justify-end">
        <div
          v-if="
            getUserAuthenticated.speciality &&
            getUserAuthenticated.speciality.name === 'Ingeniero de Campo'
          "
          class="flex mt-4"
        >
          <button
            class="
              flex
              text-white
              border
              text-sm
              font-medium
              bg-primary
              border-primary
              py-2
              ml-4
              px-3
              my-auto
            "
            :disabled="loadingHandleTaskerDailyConfig"
            @click="onHandleTaskerDailyConfig"
          >
            <LoadingButtonIcon v-if="loadingHandleTaskerDailyConfig" />
            {{
              getTaskerDailyConfig && getTaskerDailyConfig.isClosed
                ? "Abrir"
                : "Cerrar"
            }}
            día
          </button>
        </div>
        <div class="flex mt-4 ml-4">
          <button
            @click="mode = 'table'"
            class="mr-1"
            :class="
              mode == 'table'
                ? 'rounded bg-primary py-2 px-2 text-white font-medium flex'
                : 'rounded  py-2 px-2 text-primary font-medium flex'
            "
          >
            <div class="mr-1">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M7.125 8.75V7.25H20.75V8.75ZM7.125 12.75V11.25H20.75V12.75ZM7.125 16.75V15.25H20.75V16.75ZM4.075 8.825Q3.725 8.825 3.487 8.587Q3.25 8.35 3.25 8Q3.25 7.65 3.487 7.412Q3.725 7.175 4.075 7.175Q4.425 7.175 4.662 7.412Q4.9 7.65 4.9 8Q4.9 8.35 4.662 8.587Q4.425 8.825 4.075 8.825ZM4.075 12.825Q3.725 12.825 3.487 12.587Q3.25 12.35 3.25 12Q3.25 11.65 3.487 11.412Q3.725 11.175 4.075 11.175Q4.425 11.175 4.662 11.412Q4.9 11.65 4.9 12Q4.9 12.35 4.662 12.587Q4.425 12.825 4.075 12.825ZM4.075 16.825Q3.725 16.825 3.487 16.587Q3.25 16.35 3.25 16Q3.25 15.65 3.487 15.412Q3.725 15.175 4.075 15.175Q4.425 15.175 4.662 15.412Q4.9 15.65 4.9 16Q4.9 16.35 4.662 16.587Q4.425 16.825 4.075 16.825Z"
                  :fill="mode == 'table' ? 'white' : '#0057ff'"
                />
              </svg>
            </div>
            <!-- Tabla -->
          </button>
          <button
            @click="mode = 'graphics'"
            :class="
              mode == 'graphics'
                ? 'rounded bg-primary py-2 px-2 text-white font-medium flex'
                : 'rounded  py-2 px-2 text-primary font-medium flex'
            "
          >
            <div class="mr-1">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M12.75 11.25H19.975Q19.675 8.35 17.663 6.325Q15.65 4.3 12.75 4.025ZM11.25 19.975V4.025Q8.15 4.3 6.075 6.587Q4 8.875 4 12Q4 15.125 6.075 17.413Q8.15 19.7 11.25 19.975ZM12.75 19.975Q15.65 19.7 17.675 17.675Q19.7 15.65 19.975 12.75H12.75ZM12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12ZM12 21.5Q10.025 21.5 8.288 20.75Q6.55 20 5.275 18.725Q4 17.45 3.25 15.712Q2.5 13.975 2.5 12Q2.5 10.025 3.25 8.287Q4 6.55 5.275 5.275Q6.55 4 8.288 3.25Q10.025 2.5 12 2.5Q13.975 2.5 15.7 3.25Q17.425 4 18.712 5.287Q20 6.575 20.75 8.3Q21.5 10.025 21.5 12Q21.5 13.95 20.75 15.688Q20 17.425 18.725 18.712Q17.45 20 15.713 20.75Q13.975 21.5 12 21.5Z"
                  :fill="mode == 'table' ? '#0057ff' : 'white'"
                />
              </svg>
            </div>
            <!-- Gráficos -->
          </button>
        </div>
        <div class="d-flex mt-4">
          <button
            class="
              flex
              text-white
              border
              text-sm
              font-medium
              bg-primary
              border-primary
              py-2
              ml-4
              px-3
              my-auto
            "
            :disabled="loadingExportReport"
            @click="onExportReport"
          >
            <LoadingButtonIcon v-if="loadingExportReport" /> Exportar Reporte
          </button>
        </div>
      </div>
    </div>

    <div v-if="mode === 'graphics'" class="grid grid-cols-2 my-2 mr-3 ml-2">
      <div class="flex justify-start flex-col relative">
        <p class="text-sm text-secondary font-medium">Por fecha:</p>
        <input
          v-model="date"
          type="date"
          class="
            relative
            p-1
            bg-white
            rounded
            border border-gray-400
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none
            px-4
            lg:w-40
          "
        />
      </div>
      <div class="flex justify-end">
        <div class="flex mt-4 ml-4">
          <button
            @click="mode = 'table'"
            class="mr-1"
            :class="
              mode == 'table'
                ? 'rounded bg-primary py-2 px-2 text-white font-medium flex'
                : 'rounded  py-2 px-2 text-primary font-medium flex'
            "
          >
            <div class="mr-1">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M7.125 8.75V7.25H20.75V8.75ZM7.125 12.75V11.25H20.75V12.75ZM7.125 16.75V15.25H20.75V16.75ZM4.075 8.825Q3.725 8.825 3.487 8.587Q3.25 8.35 3.25 8Q3.25 7.65 3.487 7.412Q3.725 7.175 4.075 7.175Q4.425 7.175 4.662 7.412Q4.9 7.65 4.9 8Q4.9 8.35 4.662 8.587Q4.425 8.825 4.075 8.825ZM4.075 12.825Q3.725 12.825 3.487 12.587Q3.25 12.35 3.25 12Q3.25 11.65 3.487 11.412Q3.725 11.175 4.075 11.175Q4.425 11.175 4.662 11.412Q4.9 11.65 4.9 12Q4.9 12.35 4.662 12.587Q4.425 12.825 4.075 12.825ZM4.075 16.825Q3.725 16.825 3.487 16.587Q3.25 16.35 3.25 16Q3.25 15.65 3.487 15.412Q3.725 15.175 4.075 15.175Q4.425 15.175 4.662 15.412Q4.9 15.65 4.9 16Q4.9 16.35 4.662 16.587Q4.425 16.825 4.075 16.825Z"
                  :fill="mode == 'table' ? 'white' : '#0057ff'"
                />
              </svg>
            </div>
            <!-- Tabla -->
          </button>
          <button
            @click="mode = 'graphics'"
            :class="
              mode == 'graphics'
                ? 'rounded bg-primary py-2 px-2 text-white font-medium flex'
                : 'rounded  py-2 px-2 text-primary font-medium flex'
            "
          >
            <div class="mr-1">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M12.75 11.25H19.975Q19.675 8.35 17.663 6.325Q15.65 4.3 12.75 4.025ZM11.25 19.975V4.025Q8.15 4.3 6.075 6.587Q4 8.875 4 12Q4 15.125 6.075 17.413Q8.15 19.7 11.25 19.975ZM12.75 19.975Q15.65 19.7 17.675 17.675Q19.7 15.65 19.975 12.75H12.75ZM12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12ZM12 21.5Q10.025 21.5 8.288 20.75Q6.55 20 5.275 18.725Q4 17.45 3.25 15.712Q2.5 13.975 2.5 12Q2.5 10.025 3.25 8.287Q4 6.55 5.275 5.275Q6.55 4 8.288 3.25Q10.025 2.5 12 2.5Q13.975 2.5 15.7 3.25Q17.425 4 18.712 5.287Q20 6.575 20.75 8.3Q21.5 10.025 21.5 12Q21.5 13.95 20.75 15.688Q20 17.425 18.725 18.712Q17.45 20 15.713 20.75Q13.975 21.5 12 21.5Z"
                  :fill="mode == 'table' ? '#0057ff' : 'white'"
                />
              </svg>
            </div>
            <!-- Gráficos -->
          </button>
        </div>
        <div class="d-flex mt-4">
          <button
            class="
              flex
              text-white
              border
              text-sm
              font-medium
              bg-primary
              border-primary
              py-2
              ml-4
              px-3
              my-auto
            "
            :disabled="loadingExportReport"
            @click="onExportReport"
          >
            <LoadingButtonIcon v-if="loadingExportReport" /> Exportar Reporte
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="mode === 'table'"
      class="flex flex-wrap gap-y-2 w-full gap-x-5 mr-3 ml-2 my-4"
    >
      <div>
        <p class="text-sm text-secondary font-medium">Por fecha:</p>
        <input
          v-model="date"
          type="date"
          class="
            relative
            p-1
            bg-white
            rounded
            border border-gray-400
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none
            px-4
            lg:w-40
          "
        />
      </div>
      <div>
        <p class="text-sm text-secondary font-medium">Por partida:</p>
        <select
          v-model="departure"
          class="
            relative
            py-2
            px-2
            bg-white
            rounded-sm
            border border-gray-400
            outline-none
            text-gray-700
            placeholder-gray-400
            w-40
          "
          placeholder="Seleccione"
        >
          <option value="">Todos</option>
          <option
            v-for="departure in getOnlyDepartures"
            :key="departure.key"
            :value="departure.key"
          >
            {{ departure.name }}
          </option>
        </select>
      </div>
      <div>
        <p class="text-sm text-secondary font-medium">Por subpartida:</p>
        <select
          v-model="subDeparture"
          class="
            relative
            py-2
            px-2
            bg-white
            rounded-sm
            border border-gray-400
            outline-none
            text-gray-700
            placeholder-gray-400
            w-40
          "
          placeholder="Seleccione"
        >
          <option value="">Todos</option>
          <option
            v-for="subDeparture in getOnlySubDepartures"
            :key="subDeparture.key"
            :value="subDeparture.key"
          >
            {{ subDeparture.name }}
          </option>
        </select>
      </div>
      <div>
        <p class="text-sm text-secondary font-medium">Por grupo:</p>
        <select
          v-model="group"
          class="
            relative
            py-2
            px-2
            bg-white
            rounded-sm
            border border-gray-400
            outline-none
            text-gray-700
            placeholder-gray-400
            w-40
          "
          placeholder="Seleccione"
        >
          <option value="">Todos</option>
          <option v-for="group in groups" :key="group.key" :value="group.key">
            {{ group.name }}
          </option>
        </select>
      </div>
      <div>
        <p class="text-sm text-secondary font-medium">Por tareador:</p>
        <select
          v-model="foreman"
          class="
            relative
            py-2
            px-2
            bg-white
            rounded-sm
            border border-gray-400
            outline-none
            text-gray-700
            placeholder-gray-400
            w-40
          "
          placeholder="Seleccione"
        >
          <option value="">Todos</option>
          <option
            v-for="foreman in foremen"
            :key="foreman.key"
            :value="foreman.key"
          >
            {{ foreman.lastName }} {{ foreman.lastName2 }} {{ foreman.name }}
          </option>
        </select>
      </div>
      <div>
        <p class="text-sm text-secondary font-medium">Por sector:</p>
        <select
          v-model="sector"
          class="
            relative
            py-2
            px-2
            bg-white
            rounded-sm
            border border-gray-400
            outline-none
            text-gray-700
            placeholder-gray-400
            w-40
          "
          placeholder="Seleccione"
        >
          <option value="">Todos</option>
          <option
            v-for="sector in sectors"
            :key="sector.key"
            :value="sector.key"
          >
            {{ sector.name }}
          </option>
        </select>
      </div>
    </div>

    <Table
      v-if="mode === 'table'"
      :search="search"
      :date="date"
      :departure="departure"
      :sub-departure="subDeparture"
      :group="group"
      :sector="sector"
      :foreman="foreman"
      :groups="groups"
      @update:groups="groups = $event"
      :subDepartures="subDepartures"
      @update:subDepartures="subDepartures = $event"
      :departures="departures"
      @update:departures="departures = $event"
      :sectors="sectors"
      @update:sectors="sectors = $event"
      :foremen="foremen"
      @update:foremen="foremen = $event"
    />
    <Graphics v-if="mode === 'graphics'" :date="date" />
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import Table from "./components/Table";
import Graphics from "./components/Graphics";

const store = useStore();

const mode = ref("table");
const search = ref("");
const date = ref("");
const departure = ref("");
const departures = ref([]);
const subDeparture = ref("");
const subDepartures = ref([]);
const group = ref("");
const groups = ref([]);
const sector = ref("");
const sectors = ref([]);
const foreman = ref("");
const foremen = ref([]);
const loadingExportReport = ref(false);
const loadingTaskerDailyConfig = ref(false);
const loadingHandleTaskerDailyConfig = ref(false);

const getUserAuthenticated = computed(() => store.getters.getUserAuthenticated);
const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);
const getTaskerDailyConfig = computed(() => store.getters.getTaskerDailyConfig);

const getOnlyDepartures = computed(() => {
  let departuresKeySelected = [];
  let departuresSelected = [];

  departures.value.forEach((departure) => {
    if (
      !departure.isSubDeparture &&
      !departuresKeySelected.includes(departure.key)
    ) {
      departuresKeySelected.push(departure.key);
      departuresSelected.push(departure);
    }

    if (
      departure.isSubDeparture &&
      departure.parentDeparture &&
      !departuresKeySelected.includes(departure.parentDeparture.key)
    ) {
      departuresKeySelected.push(departure.parentDeparture.key);
      departuresSelected.push(departure.parentDeparture);
    }
  });

  return departuresSelected;
});

const getOnlySubDepartures = computed(() => {
  let subDeparturesKeySelected = [];
  let subDeparturesSelected = [];

  if (departure.value) {
    departures.value.forEach((d) => {
      if (
        d.parentDeparture &&
        d.parentDeparture.key === departure.value &&
        d.isSubDeparture
      ) {
        if (!subDeparturesKeySelected.includes(d.key)) {
          subDeparturesKeySelected.push(d.key);
          subDeparturesSelected.push(d);
        }
      }
    });
  }

  return subDeparturesSelected;
});

watch(
  () => departure.value,
  (newVal, oldVal) => {
    if (newVal !== oldVal) subDeparture.value = "";
  }
);

const loadTaskerDailyConfig = async () => {
  try {
    loadingTaskerDailyConfig.value = true;

    await store.dispatch("findTaskerDailyConfig", {
      date: date.value,
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingTaskerDailyConfig.value = false;
  }
};

watch(
  () => date.value,
  (value) => {
    if (value) loadTaskerDailyConfig();
  }
);

const init = () => {
  date.value = getWeekInitialDate.value;
};

init();

const onHandleTaskerDailyConfig = async () => {
  try {
    loadingHandleTaskerDailyConfig.value = true;

    if (!getTaskerDailyConfig.value) {
      await store.dispatch("createTaskerDailyConfig", {
        config: {
          date: date.value,
          isClosed: true,
        },
      });
    } else {
      await store.dispatch("updateTaskerDailyConfig", {
        config: {
          key: getTaskerDailyConfig.value.key,
          isClosed: !getTaskerDailyConfig.value.isClosed,
        },
      });
    }
  } catch (error) {
    console.error(error);
  } finally {
    loadingHandleTaskerDailyConfig.value = false;
  }
};

const onExportReport = async () => {
  try {
    loadingExportReport.value = true;

    const reportUrl = await store.dispatch("exportTaskerTemplateDaily", {
      dateFrom: date.value,
    });

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", "tareo-diario.xlsx");
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error(error);
  } finally {
    loadingExportReport.value = false;
  }
};
</script>
