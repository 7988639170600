<template>
  <div class="grid relative w-full lg:px-2 pt-6">
    <div
      v-if="getWorkHours && getWorkHours.length"
      class="
        overflow-x-scroll overflow-y-scroll
        max-h-96
        xl:max-h-128
        2xl:max-h-160
      "
    >
      <table>
        <thead>
          <tr>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              DNI
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Nombre
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Partida
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Subpartida
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Grupo
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Sector
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Tareador
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Horas trabajadas
            </th>
            <th class="px-6 py-2 text-sm font-medium text-gray-500 text-left">
              Día
            </th>
            <th class="px-2 py-2 text-sm font-medium text-gray-500 text-left">
              Operaciones
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-400">
          <template v-if="getWorkHours">
            <tr
              v-for="(workHour, i) in getWorkHours"
              :key="i"
              class="whitespace-nowrap"
            >
              <td class="px-6 py-4 text-sm font-light text-left text-gray-900">
                {{ workHour.user.dni }}
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-left font-light text-gray-900">
                  {{ workHour.user.lastName }}
                  {{ workHour.user.lastName2 }}
                  {{ workHour.user.name }}
                </div>
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-left font-light text-gray-900">
                  {{
                    workHour.items.length
                      ? workHour.items[0].departure.parentDepartureKey
                        ? workHour.items[0].departure.parentDeparture.name
                        : workHour.items[0].departure.name
                      : "-"
                  }}
                </div>
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-left font-light text-gray-900">
                  {{
                    workHour.items.length &&
                    workHour.items[0].departure.isSubDeparture
                      ? workHour.items[0].departure.name
                      : "-"
                  }}
                </div>
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-left font-light text-gray-900">
                  {{ workHour.items[0].group.name }}
                </div>
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-left font-light text-gray-900">
                  {{
                    workHour.items[0].sector
                      ? workHour.items[0].sector.name
                      : "-"
                  }}
                </div>
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-left font-light text-gray-900">
                  {{ workHour.items[0].group.foreman.lastName }}
                  {{ workHour.items[0].group.foreman.lastName2 }}
                  {{ workHour.items[0].group.foreman.name }}
                </div>
              </td>
              <td class="px-6 py-4">
                <div class="text-sm text-center font-light text-gray-900">
                  {{
                    workHour.items.length
                      ? workHour.items[0].workHour +
                        workHour.items[0].workExtraHour
                      : "0"
                  }}
                </div>
              </td>
              <td class="px-6 py-4 text-sm text-left font-light text-gray-900">
                {{ formatDate(workHour.date) }}
              </td>
              <td class="text-center flex">
                <div
                  v-if="
                    [
                      'c64fb811-f3f6-4160-8a34-9adb1ffdb3d6',
                      '8bad41b9-c3ba-4689-bc66-64a6816f53dd', // Playa villamar 2
                      'df213990-73b9-4a2e-82f5-6d37b1d891f5',
                      '766cef75-bfb3-4a0b-8d68-543a11bbf401', // Sol de Pimentel
                      '65281044-f259-4fff-ba5a-04b5c5f1ea07', // Sol de Chan Chan
                      '8beafab5-93f7-4acd-bce5-7bba459dca0d', // Sol de Lambayeque
                      '8ca68aae-1d7a-4fda-a45e-613aecd370e7', // Vista Alegre
                    ].includes(getWork.key) && !workHour.items[0].isAuthorized
                  "
                  class="px-2 py-4 text-sm"
                >
                  No marcó asistencia
                  <button
                    v-if="
                      getUserAuthenticated.speciality &&
                      (getUserAuthenticated.speciality.key ===
                        '1a2a9e24-5940-4ad8-8fec-92bd8a5f5c3b' ||
                        getUserAuthenticated.speciality.key ===
                          '8b355fdc-e76d-4058-a37f-28dee03d1147')
                    "
                    class="
                      rounded
                      bg-primary
                      py-1
                      px-2
                      text-white
                      font-medium
                      flex
                      mb-2
                    "
                    :disabled="loadingAuthorize"
                    @click="onAuthorizeWorkHourItem(workHour)"
                  >
                    <LoadingButtonIcon v-if="loadingAuthorize" />
                    Autorizar
                  </button>
                </div>
                <button
                  class="ml-4 hover:bg-red-50 px-2 py-4"
                  @click="onDeleteWorkHour(workHour)"
                >
                  <!-- <span class="text-red-500 font-medium">Eliminar</span> -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M5.28544 2.14118H5.14258C5.22115 2.14118 5.28544 2.0769 5.28544 1.99833V2.14118H10.714V1.99833C10.714 2.0769 10.7783 2.14118 10.8569 2.14118H10.714V3.4269H11.9997V1.99833C11.9997 1.36797 11.4872 0.855469 10.8569 0.855469H5.14258C4.51222 0.855469 3.99972 1.36797 3.99972 1.99833V3.4269H5.28544V2.14118ZM14.2854 3.4269H1.71401C1.39794 3.4269 1.14258 3.68225 1.14258 3.99833V4.56976C1.14258 4.64833 1.20686 4.71261 1.28544 4.71261H2.36401L2.80508 14.0519C2.83365 14.6608 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6626 13.1944 14.0519L13.6354 4.71261H14.714C14.7926 4.71261 14.8569 4.64833 14.8569 4.56976V3.99833C14.8569 3.68225 14.6015 3.4269 14.2854 3.4269ZM11.9158 13.8555H4.08365L3.65151 4.71261H12.3479L11.9158 13.8555Z"
                      fill="#E00F57"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div
      v-if="getWorkHours && !getWorkHours.length"
      class="h-max p-4 text-left text-secondary font-light bg-lightgray"
    >
      <div class="flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-secondary mx-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 8v4m0 4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"
          />
        </svg>
        No se ha registrado información de tareo.
      </div>
    </div>

    <DeleteWorkHourModal
      v-if="modalDeleteWorkHour"
      :open="modalDeleteWorkHour"
      :workHour="workHourSelected"
      @update:open="modalDeleteWorkHour = $event"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import DeleteWorkHourModal from "../modals/DeleteWorkHour";

const props = defineProps({
  search: {
    type: String,
  },
  date: {
    type: String,
  },
  departure: {
    type: String,
  },
  subDeparture: {
    type: String,
  },
  group: {
    type: String,
  },
  sector: {
    type: String,
  },
  foreman: {
    type: String,
  },
});

const emit = defineEmits([
  "update:groups",
  "update:sectors",
  "update:foremen",
  "update:departures",
]);

const store = useStore();

const loadingSearch = ref(false);
const timerToSearch = ref(null);
const workHourSelected = ref(null);
const modalDeleteWorkHour = ref(false);
const loadingAuthorize = ref(false);

const getUserAuthenticated = computed(() => store.getters.getUserAuthenticated);
const getWork = computed(() => store.getters.getWork);
const getDepartures = computed(() => store.getters.getDepartures);
const getWorkHours = computed(() => store.getters.getWorkHours);
const getListGroups = computed(() => store.getters.getListGroups);

const onSearchUser = () => {
  clearTimeout(timerToSearch.value);

  timerToSearch.value = setTimeout(async () => {
    await loadWorkHours();
  }, 200);
};

watch(
  () => props.search,
  () => {
    onSearchUser();
  }
);

watch(
  () => props.date,
  () => {
    loadWorkHours(true);
  }
);
watch(
  () => props.departure,
  () => loadWorkHours()
);
watch(
  () => props.subDeparture,
  () => loadWorkHours()
);
watch(
  () => props.group,
  () => loadWorkHours()
);
watch(
  () => props.sector,
  () => loadWorkHours()
);
watch(
  () => props.foreman,
  () => loadWorkHours()
);

const loadWorkHours = async (refreshFilters) => {
  try {
    store.dispatch("resetWorkHours");

    loadingSearch.value = true;

    if (!props.date) return;

    const filter = {
      date: props.date,
      report: true,
    };
    if (props.group) filter.groupKey = props.group;
    if (props.sector) filter.sectorKey = props.sector;
    if (props.search) filter.q = props.search;

    const allWorkHoursFormatted = await store.dispatch("getAllWorkHours", {
      filter: filter,
      departureKey: props.subDeparture || props.departure,
      groupKey: props.group,
      sectorKey: props.sector,
      foremanKey: props.foreman,
    });

    if (refreshFilters) {
      const groupsSelected = [];
      const groupsKeySelected = [];

      const sectorsSelected = [];
      const sectorsKeySelected = [];

      const foremenSelected = [];
      const foremenKeySelected = [];

      const departuresSelected = [];
      const departuresKeySelected = [];

      allWorkHoursFormatted.forEach((wh) => {
        if (!groupsKeySelected.includes(wh.items[0].group.key)) {
          groupsKeySelected.push(wh.items[0].group.key);
          groupsSelected.push(wh.items[0].group);
        }

        if (
          wh.items[0].sector &&
          !sectorsKeySelected.includes(wh.items[0].sector.key)
        ) {
          sectorsKeySelected.push(wh.items[0].sector.key);
          sectorsSelected.push(wh.items[0].sector);
        }

        if (!foremenKeySelected.includes(wh.items[0].group.foreman.key)) {
          foremenKeySelected.push(wh.items[0].group.foreman.key);
          foremenSelected.push(wh.items[0].group.foreman);
        }

        if (!departuresKeySelected.includes(wh.items[0].departure.key)) {
          departuresKeySelected.push(wh.items[0].departure.key);
          departuresSelected.push(wh.items[0].departure);
        }
      });

      emit("update:groups", groupsSelected);
      emit("update:sectors", sectorsSelected);
      emit("update:foremen", foremenSelected);
      emit("update:departures", departuresSelected);
    }
  } catch (error) {
    console.error(error);
  } finally {
    loadingSearch.value = false;
  }
};

const init = async () => {
  if (!getDepartures.value) await store.dispatch("getAllDepartures");

  // if (getDepartures.value?.length) departure.value = getDepartures.value[0].key;

  if (!getListGroups.value) await store.dispatch("getListAllGroups");

  // if (getListGroups.value?.length) group.value = getListGroups.value[0].key;
  await loadWorkHours(true);
};

init();

const formatDate = (dateString) => {
  return dayjs(dateString).format("DD/MM/YYYY");
};

const onAuthorizeWorkHourItem = async (workHour) => {
  try {
    loadingAuthorize.value = true;

    await store.dispatch("authorizeWorkHourItem", {
      workHourItemKey: workHour.items[0].key,
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingAuthorize.value = false;
  }
};

const onDeleteWorkHour = (workHour) => {
  workHourSelected.value = workHour;
  modalDeleteWorkHour.value = true;
};
</script>
