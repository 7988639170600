<template>
  <Form
    @submit="onImport"
    :validation-schema="{
      file: '',
    }"
  >
    <div class="pt-8">
      <h4 class="mb-6 text-secondary">
        Importar tareo semanal <br />
        {{ props.date }}
      </h4>
    </div>
    <div class="">
      <h6 class="text-sm">
        Descargar plantilla para importar tareo
        <a
          class="text-primary underline"
          href="https://storage.googleapis.com/bildin-sac.appspot.com/templates/plantilla-tareo-semanal.xlsx"
          >aquí</a
        >.
      </h6>
    </div>
    <div class="h-2 w-full border-dashed border-b py-2"></div>
    <div class="mb-4 mt-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-sm">Cargar archivo</label>
        <input
          id="file"
          type="file"
          class="
            relative
            py-3
            px-2
            w-full
            bg-white
            rounded
            shadow
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none focus:shadow-outline
          "
          placeholder="Seleccione"
          @change="previewFiles"
          required
        />
      </div>
      <div>
        <ErrorMessage name="file" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <Alert
      v-if="responseMessage"
      :type="responseType"
      :text="responseMessage"
    ></Alert>
    <div>
      <button
        :disabled="loadingImport"
        class="rounded bg-primary py-2 px-6 text-white flex"
        type="submit"
      >
        <LoadingButtonIcon v-if="loadingImport" />
        Importar
      </button>
    </div>
  </Form>
</template>

<script setup>
import { defineEmits, ref, defineProps } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["onComplete"]);
const store = useStore();

const props = defineProps({
  date: {
    type: String,
    required: true,
  },
});

let fileToUpload = ref(null);
let responseType = ref(null);
let responseMessage = ref(null);
let loadingImport = ref(false);

const onImport = async () => {
  try {
    loadingImport.value = true;
    responseType.value = null;
    responseMessage.value = null;

    await store.dispatch("importTaskerWeekly", {
      file: fileToUpload.value,
      date: props.date,
    });

    responseType.value = "success";
    responseMessage.value = "Se importó el tareo semanal con éxito";

    emit("onComplete");

    fileToUpload.value = null;
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message || "Ocurrió un error al subir la información";

    console.error(error);
  } finally {
    loadingImport.value = false;
  }
};

const previewFiles = (event) => {
  fileToUpload.value = event.target.files[0];
};
</script>
