<template>
  <Menu as="div" class="relative inline-block text-left ml-4 my-auto">
    <div>
      <MenuButton
        class="
          text-white
          border
          text-sm
          font-medium
          bg-greenDark
          border-greenDark
          py-2
          px-5 px-6
          mx-2
          flex
        "
      >
        <LoadingButtonIcon v-if="loadingExport" /> Exportar
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="
          absolute
          right-0
          z-10
          mt-2
          w-56
          origin-top-right
          divide-y divide-gray-100
          rounded-md
          bg-white
          shadow-lg
          ring-1 ring-black ring-opacity-5
          focus:outline-none
        "
      >
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <button
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'px-4 py-2 text-sm w-full text-left',
              ]"
              :disabled="loadingExport"
              @click="onExportReportWeekly"
            >
              Formato S10
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'px-4 py-2 text-sm w-full text-left',
              ]"
              :disabled="loadingExportFormatS10v2"
              @click="onExportReportWeeklyFormatS10v2"
            >
              Formato S10 v2
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'px-4 py-2 text-sm w-full text-left',
              ]"
              :disabled="loadingExportFormatOpera"
              @click="onExportReportWeeklyFormatOpera"
            >
              Formato Opera
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'px-4 py-2 text-sm w-full text-left',
              ]"
              :disabled="loadingExportHistogram"
              @click="onExportReportWeekly2"
            >
              Resumen Semanal
            </button>
          </MenuItem>
          <MenuItem
            v-slot="{ active }"
            v-if="
              [
                '9d078b52-f97e-4bee-aab8-d017dde919ff',
                '36919e48-3939-4391-9ebc-f5bb4ca8646b',
                'bfb4ac6f-63f9-49f8-b92c-382c9e82dfb5',
              ].includes(getWork.key)
            "
          >
            <button
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'px-4 py-2 text-sm w-full text-left',
              ]"
              :disabled="loadingPSVExport"
              @click="onExportPSVWeeklyReport"
            >
              Tareo Semanal PSV
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'px-4 py-2 text-sm w-full text-left',
              ]"
              :disabled="loadingExportTemplateWeekly"
              @click="onExportTemplateWeekly"
            >
              Planilla Semanal
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'px-4 py-2 text-sm w-full text-left',
              ]"
              :disabled="loadingExportHistory"
              @click="onExportHistory"
            >
              Base de datos
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { computed, defineProps, ref } from "vue";
import { useStore } from "vuex";
const props = defineProps({
  dateFrom: {
    type: String,
  },
  dateTo: {
    type: String,
  },
});
const store = useStore();
const loadingExport = ref(false);
const loadingExportFormatOpera = ref(false);
const loadingExportTemplateWeekly = ref(false);
const loadingExportHistory = ref(false);
const loadingExportFormatS10v2 = ref(false);
const loadingPSVExport = ref(false);

const getWork = computed(() => store.getters.getWork);

const onExportReportWeekly = async () => {
  try {
    loadingExport.value = true;

    const reportUrl = await store.dispatch("exportTaskerWeeklyReport", {
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    });
    // const reportUrl =
    //   "https://storage.googleapis.com/bildin-sac-dev/media/work/8d8a8221-3fa2-4e74-8c0f-def2bfa796fd/report/8d8a8221-3fa2-4e74-8c0f-def2bfa796fd/2a7ad81a-b768-4703-8b0a-a1c2550f1852.xlsx";

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", `reporte-formato-s10.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error(error);
  } finally {
    loadingExport.value = false;
  }
};

const onExportReportWeeklyFormatOpera = async () => {
  try {
    loadingExportFormatOpera.value = true;

    const reportUrl = await store.dispatch(
      "exportTaskerWeeklyFormatOperaReport",
      {
        dateFrom: props.dateFrom,
        dateTo: props.dateTo,
      }
    );

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", "reporte-formato-opera.xlsx");
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error(error);
  } finally {
    loadingExportFormatOpera.value = false;
  }
};

const onExportReportWeeklyFormatS10v2 = async () => {
  try {
    loadingExportFormatOpera.value = true;

    const reportUrl = await store.dispatch(
      "exportTaskerWeeklyFormatS10v2Report",
      {
        dateFrom: props.dateFrom,
        dateTo: props.dateTo,
      }
    );

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", "reporte-formato-S10v2.xlsx");
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error(error);
  } finally {
    loadingExportFormatOpera.value = false;
  }
};

const onExportReportWeekly2 = async () => {
  try {
    loadingExport.value = true;

    const reportUrl = await store.dispatch(
      "exportAllWorkHoursAndCategoryCost",
      {
        dateFrom: props.dateFrom,
        dateTo: props.dateTo,
      }
    );

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", `resumen-semanal.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error(error);
  } finally {
    loadingExport.value = false;
  }
};

const onExportTemplateWeekly = async () => {
  try {
    loadingExportTemplateWeekly.value = true;

    const reportUrl = await store.dispatch("exportTaskerTemplateWeekly", {
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    });

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", `plantilla-semanal.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error(error);
  } finally {
    loadingExportTemplateWeekly.value = false;
  }
};

const onExportHistory = async () => {
  try {
    loadingExportHistory.value = true;

    const reportUrl = await store.dispatch("exportTaskerHistoryReport", {
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    });

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", `tareo-semanal-psv.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error(error);
  } finally {
    loadingPSVExport.value = false;
  }
};

const onExportPSVWeeklyReport = async () => {
  try {
    loadingPSVExport.value = true;

    const reportUrl = await store.dispatch("exportPSVWeeklyReport", {
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    });

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", `tareo-base-de-datos.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error(error);
  } finally {
    loadingExportHistory.value = false;
  }
};
</script>
